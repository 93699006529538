import React, { Component } from 'react'
import chapters from '../../assets/chapters'

import VideoCard from '../../components/VideoCard'

class ChaptersRow extends Component {
  render () {
    let className = this.props.open ? 'open' : 'close'

    return (
      <section
        id="chapters-row"
        className={className} >
        <div
          className="overlay"
          onClick={ this.props.closeChapters }
        ></div>
        <div className="card-row">
          { chapters.chapters.map( chapter => (
            <VideoCard
              key={chapter.chapter}
              id={chapter.chapter}
              chapter={chapter}
            />
          ))}
        </div>
      </section>
    )
  }
}

export default ChaptersRow
import React, { Component } from 'react'
import SimpleLink from 'components/SimpleLink'
import ActionButton from 'components/ActionButton'

class Disclaimer extends Component {
  render = () => (
    <div className="page-wrapper" id="disclaimer-page">
      <div className="centered-content">
        <h2>Avant de commencer</h2>
        <p className="center">
          Cette formation interactive vous permettra, en tant que superviseure et superviseur en milieu minier, de mieux communiquer avec votre personnel.<br /><br />
          Vous suivrez François, un surperviseur sur le point de vivre sa première journée de travail à son nouveau poste.<br /><br />
          Tout au long de sa journée, François sera confronté à des situations propres au rôle du superviseur.<br /><br />
          Vous devrez identifier quels comportements ont pu mener à des situations d'erreur ou d'incompréhension.
        </p>
        <ActionButton
          label="Démarrer"
          to="/chapter/1" />
      </div>
      <SimpleLink
        bordered
        label="Retour"
        to="/"
        id="landing-back-link" />
    </div>
  )
}

export default Disclaimer

import React, { Component } from 'react'

class RadioList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selected: this.props.default || -1
    }
  }

  onChange = e => {
    let selected = e.target.value

    this.setState({ selected: selected }, () => {
      this.props.onChange(this.state.selected)
    })
  }

  render () {
    return (
      <div className="radio-list">
        { this.props.items.map(({ label }, index) => {
          const itemID = 'item-' + index
          const checked = index === this.state.selected
          return (
            <div className="radio-list-item" key={itemID}>
              <input
                type={ 'radio' }
                name={ 'radio-list'}
                value={ index }
                id={ itemID }
                className="selectable-card-input"
                onChange={ this.onChange }
                defaultChecked={ checked }/>
              <label
                htmlFor={ itemID }
                className="radio-list-label">
                <span>{ label }</span>
              </label>
            </div>
          )
        }) }
      </div>
    )
  }
}

export default RadioList

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SimpleLink extends Component {
  render () {
    return (
      <Link
        className={"navigation-link " + (this.props.bordered ? "bordered" : "")}
        id={this.props.id}
        to={this.props.to} >
        {this.props.label || this.props.children}
      </Link>
    )
  }
}

export default SimpleLink

import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import Credits from './Home/Credits'
import Chapters from './Home/Chapters'
import Disclaimer from './Home/Disclaimer'
import Landing from './Home/Landing'
import End from './End'

class Home extends Component {
  render () {
    return (
      <main id="home-page">
        <Switch>
          <Route exact path="/credits" component={Credits} />
          <Route exact path="/chapters" component={Chapters} />
          <Route exact path="/disclaimer" component={Disclaimer} />
          <Route exact path="/end" component={End} />
          <Route component={Landing} />
        </Switch>
      </main>
    )
  }
}

export default Home

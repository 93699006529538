import React, { Component } from 'react';
import './style/main.scss';

import { Switch, Route } from 'react-router-dom'
import Home from './scenes/Home'
import Player from './scenes/Player'

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/chapter/:id" component={Player} />
        <Route component={Home} />
      </Switch>
    );
  }
}

export default App;

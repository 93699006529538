import React, { Component } from 'react'

import ActionButton from 'components/ActionButton'

class End extends Component {
  render = () => (
    <div className="page-wrapper" id="end-page">
      <div className="centered-content">
        <h1>Fin</h1>
        <h3>Les 4 concepts de la communication</h3>
        <p className="left">
          <ol>
            <li>S'assurer d'avoir l'attention de son interlocuteur</li>
            <li>Expliquer pourquoi la tâche doit être effectuée</li>
            <li>Préciser les circonstances de l'action (quand, où, comment… )</li>
            <li>Demander une rétroaction pour s'assurer d'une bonne compréhension</li>
          </ol>
        </p>
        <ActionButton
          label="Crédits"
          to="/credits" />
      </div>
    </div>
  )
}

export default End

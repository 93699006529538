import React from 'react'
import { Player, ControlBar, BigPlayButton } from 'video-react'
import AbstractPlayer from './AbstractPlayer'


class HTML5Player extends AbstractPlayer {
  componentDidMount () {
    this.refs.video.load()
    this.refs.video.subscribeToStateChange(this.handleStateChange)
  }

  /**
   * The video itself, embedded inside the abstract player
   * @return {*}
   */
  videoContent = () => {
    return (
      <div
        className="html-frame-container">
        <Player
          ref="video"
          autoplay
          fluid={false}
          width="100%"
          height="100%"
          preload="metadata">
          <source src={process.env.PUBLIC_URL + "/videos/1080/" + this.props.link } />
          <ControlBar disabled />
          <BigPlayButton disabled />
        </Player>
      </div>)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.link !== this.props.link) {
        this.refs.video.load()
    }
  }

  playPause = () => {
    if (this.state.player.paused) {
      this.refs.video.play()
    } else {
      this.refs.video.pause()
    }
  }

  pause = () => {
    this.refs.video.pause()
  }

  goForward = () => {
    this.refs.video.forward(10)
  }

  goBackward = () => {
    this.refs.video.replay(10)
  }

  goTo = (timecode) => {
    this.refs.video.seek(timecode)
  }

  handleStateChange = (playerState, prevState) => {
    if(prevState.readyState !== 4 && playerState.readyState === 4) {
      this.refs.video.play()
    }

    if(playerState.ended) {
      this.props.onEnd()
    }

    this.setState({
      player: playerState
    })
  }
}

export default HTML5Player

import React, { Component } from 'react'
import ActionButton from 'components/ActionButton'
import chapters from '../../assets/chapters'

class EndScreen extends Component {
  render () {
    let isEnd = (this.props.chapterID + 1) >= chapters.chapters.length
    let nextURL = isEnd ? "/end" : "/chapter/" + (this.props.chapterID + 2)
    let nextMessage = isEnd ? "Suivant" : "Chapitre suivant"
    return (
      <section className="page-wrapper" id="chapter-end-screen">
        <div className="centered-content">
          <h3>
            { this.props.chapter.quizz.answers[this.props.answerID].correct ?
              "Bonne réponse" :
              "Mauvaise réponse"
            }
          </h3>
          <p>
            { this.props.chapter.quizz.answers[this.props.answerID].message }
          </p>
            { this.props.chapter.quizz.answers[this.props.answerID].correct ?
              <div className="button-row vertical">
                <ActionButton
                  label={nextMessage}
                  to={nextURL}
                /><br />
                <ActionButton
                  label="Recommencer le chapitre"
                  callback={ this.props.restartChapter }
                />
              </div> :
              <div className="button-row horizontal">
                <ActionButton
                  label="Recommencer le chapitre"
                  callback={ this.props.restartChapter }
                /><br />
                <ActionButton
                  label={nextMessage}
                  to={nextURL}
                />
              </div>
            }
        </div>
      </section>
    )
  }
}


export default EndScreen

import React, { Component } from 'react'
import ActionButton from 'components/ActionButton'
import RadioList from 'components/RadioList'

class QuestionScreen extends Component {
  constructor (props) {
    super(props)

    this.state = {
      answer: -1,
      answered: false
    }
  }

  onAnswerChange = (answerIndex) => {
    this.setState({
      answer: answerIndex
    })
  }

  render () {
    return this.state.answered ? this.answerScreen() : this.questionScreen()
  }

  questionScreen () {
    return (
      <section className="page-wrapper" id="question-screen">
        <div className="centered-content">
          <h3>{ this.props.chapter.quizz.question }</h3>
          <RadioList
            items={ this.props.chapter.quizz.answers }
            onChange={ this.onAnswerChange }
          />
          <ActionButton
            label="Valider"
            callback={this.onConfirm}
            active={ this.state.answer !== -1 }
          />
        </div>
      </section>
    )
  }

  answerScreen () {
    return (
      <section className="page-wrapper" id="question-screen">
        <div className="centered-content">
          <h3>{ this.props.chapter.quizz.answers[this.state.answer].correct ?
                "Bonne réponse" :
                "Mauvaise réponse"
              }
          </h3>
          <br />
          <ActionButton
            label="Continuer"
            callback={this.onContinue}
          />
        </div>
      </section>
    )
  }

  onConfirm = () => {
    this.setState({
      answered: true
    })
  }

  onContinue = () => {
    this.props.onAnswer(this.state.answer)
  }
}

export default QuestionScreen

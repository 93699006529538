import React, { Component } from 'react'
import HTML5Player from './HTML5Player'

class PlayerController extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOnline: true
    }
  }

  render () {
    let Player = HTML5Player

    let link;

    if (this.props.state === 'explanatoryVideo' || this.props.state === 'whatNext') {
      link = this.props.chapter.quizz.answers[this.props.answerID].video
    } else {
      link = this.props.chapter.video
    }

    return <Player
      ref="player"
      link={link}
      name={ this.props.chapter.name }
      chapter={ this.props.chapter }
      onEnd={ this.props.onEnd }
      idle={ this.props.idle }
      openChapters={this.props.openChapters}
    />
  }

  goTo = timecode => {
    this.refs.player.goTo(timecode)
  }

  pause = () => {
    this.refs.player.pause()
  }
}

export default PlayerController

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class ActionButton extends Component {
  render () {
    return (
      <Link
        className={ "action-button " + (this.props.active || "disabled") }
        id={this.props.id}
        to={this.props.to}
        onClick={ this.onClick  }>
        <span className="action-button-label">
          { this.props.label }
        </span>
      </Link>
    )
  }

  onClick = (e) => {
    if(!this.props.active) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if(this.props.to === '#') {
      e.preventDefault()
      e.stopPropagation()
    }

    if(this.props.callback)
      this.props.callback()
  }
}

ActionButton.defaultProps = {
  active: true,
  to: '#'
}


export default ActionButton

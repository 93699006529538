import React, { Component } from 'react'

import PlayerController from './Player/PlayerController'
import QuestionScreen from './Player/QuestionScreen'
import EndScreen from './Player/EndScreen'
import SimpleLink from 'components/SimpleLink'

import chapters from '../assets/chapters'
import ChaptersRow from "./Player/ChaptersRow";

class Player extends Component {
  constructor (props) {
    super(props)

    this.state = {
      chapterState: 'mainVideo', // 'mainVideo' / 'question' / 'explanatoryVideo' / 'whatNext'
      lastAnswer: -1,
      chaptersOpen: false
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    let currChapter = Number(this.props.match.params.id)
    let nextChapter = Number(nextProps.match.params.id)

    // Changing chapter ?
    if(nextChapter !== currChapter) {
      this.refs.player.goTo(0)
      this.closeChapters()
      setTimeout(() => this.setState({
        chapterState: 'mainVideo'
      }), 100)
    }
  }

  render () {
    let chapterID = this.props.match.params.id - 1
    return (
      <section id="player-page">
        <PlayerController
          ref="player"
          key="player"
          chapter={chapters.chapters[chapterID]}
          state={this.state.chapterState}
          onEnd={this.onVideoEnd}
          answerID={this.state.lastAnswer}
          idle={ this.state.chapterState === 'question' || this.state.chapterState === 'whatNext' }
          openChapters={this.openChapters}
        />
        { this.state.chapterState === 'question' &&
          <QuestionScreen
            chapter={chapters.chapters[chapterID]}
            onAnswer={this.onAnswer}
          />
        }
        { this.state.chapterState === 'whatNext' &&
        <EndScreen
          chapterID={chapterID}
          chapter={chapters.chapters[chapterID]}
          answerID={this.state.lastAnswer}
          restartChapter={ this.restartChapter }
        />
        }
        <SimpleLink
          to="/"
          id="player-quit-link">
          <span className="player-quit-btn" />
        </SimpleLink>
        <ChaptersRow
          open={this.state.chaptersOpen}
          closeChapters={this.closeChapters}
        />
      </section>
    )
  }

  componentDidUpdate (prevProps, prevState, snapshot) {}

  onVideoEnd = () => {
    if(this.state.chapterState === 'mainVideo') {
      if(Number(this.props.match.params.id) === 5) {
        this.props.history.push('/end')
        return
      }
      this.setState({
        chapterState: 'question'
      })
    } else if (this.state.chapterState === 'explanatoryVideo') {
      this.setState({
        chapterState: 'whatNext'
      })
    }
  }

  onAnswer = (answerID) => {
    this.refs.player.goTo(0)
    setTimeout(() =>
      this.setState({
        chapterState: 'explanatoryVideo',
        lastAnswer: answerID
      }), 100)
  }

  restartChapter = () => {
    this.refs.player.goTo(0)
    setTimeout(() =>
      this.setState({
        chapterState: 'mainVideo'
      }), 100)
  }

  openChapters = () => {
    this.refs.player.pause()
    this.setState({
      chaptersOpen: true
    })
  }

  closeChapters = () => {
    this.setState({
      chaptersOpen: false
    })
  }
}

export default Player

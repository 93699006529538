import React, { Component } from 'react'

import ActionButton from 'components/ActionButton'
import SimpleLink from 'components/SimpleLink'

import INMQLogo from '../../images/INMQ.png'
import IAMGOLDLogo from '../../images/IAMGOLD.png'
import UQATLogo from '../../images/UQAT.png'

class Landing extends Component {
  render = () => (
    <div className="page-wrapper" id="landing-page">
      <div className="landing-logos">
        <a href="http://www.inmq.gouv.qc.ca/" target="_blank" rel="noopener noreferrer"><img src={ INMQLogo } alt="INMQ"/></a>
        <img src={ IAMGOLDLogo } alt="IAMGOLD"/>
        <img src={ UQATLogo } alt="INMQ"/>
      </div>
      <div className="centered-content">
        <h1>Les bases de<br />la communication</h1>
        <ActionButton
          label="Démarrer"
          to="/disclaimer" /><br />
        <SimpleLink
          bordered
          label="Chapitres"
          to="/chapters"
          id="landing-chapter-link" />
      </div>
      <SimpleLink
          bordered
          label="Crédits"
          to="/credits"
          id="landing-credits-link" />
    </div>
  )
}

export default Landing

import React, { Component } from 'react'

import SimpleLink from 'components/SimpleLink'
import VideoCard from 'components/VideoCard'

import chapters from '../../assets/chapters'

class Chapters extends Component {
  render = () => (
    <div className="page-wrapper" id="chapters-page">
      <h2>Tous les chapitres</h2>
      <div id="chapters-grid">
        { chapters.chapters.map(chapter => (
          <VideoCard
            key={chapter.chapter}
            id={chapter.chapter}
            chapter={chapter}/>
        ))}
      </div>
      <SimpleLink
        label="Retour"
        to="/"
        id="chapters-back-link" />
    </div>
  )
}

export default Chapters

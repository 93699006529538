import React, { Component } from 'react'
import SimpleLink from 'components/SimpleLink'

import INMQLogo from '../../images/INMQ.png'
import IAMGOLDLogo from '../../images/IAMGOLD.png'
import UQATLogo from '../../images/UQAT.png'

class Credits extends Component {
  render = () => (
    <div className="page-wrapper" id="credits-page">
      <div className="centered-content">
        <h2>Crédits</h2>
        <table className="credits-table">
          <tr><td>François</td><td>Pascal Binette</td></tr>
          <tr><td>Kevin</td><td>Ricky Roy</td></tr>
          <tr><td>Le capitaine</td><td>Jeffrey Vaillancourt</td></tr>
          <tr><td>Laurence</td><td>Julie Mercier</td></tr>
          <tr><td>Le foreur</td><td>David Paquin</td></tr>
          <tr><td>Joe</td><td>Valentin Dufois</td></tr>
          <tr><td>Un mineur</td><td>Jules Delorge</td></tr>
          <tr><td>Un mineur</td><td>Jeffrey Vaillancourt</td></tr>
        </table>
        <h2>Équipe Technique</h2>
        <table className="credits-table">
          <tr><td>Réalisation</td><td>Thibaut Vangheluwe</td></tr>
          <tr><td>Scénarisation</td><td>Marie-Lou Barbier</td></tr>
          <tr><td>Intégration</td><td>Valentin Dufois</td></tr>
          <tr><td>Cadrage</td><td>Louis Artiges</td></tr>
          <tr><td>Cadrage</td><td>Thibaut Vangheluwe</td></tr>
          <tr><td>Son</td><td>Marie Fagniard</td></tr>
          <tr><td>Son</td><td>Arthur Guillarme</td></tr>
          <tr><td>Best boy</td><td>Valentin Dufois</td></tr>
          <tr><td>Scripte</td><td>Marie-Lou Barbier</td></tr>
          <tr><td>Montage / Étalonnage</td><td>Thibaut Vangheluwe</td></tr>
          <tr><td>Montage / Étalonnage</td><td>Louis Artiges</td></tr>
          <tr><td>Coordination</td><td>David Paquin</td></tr>
          <tr><td>Coordination</td><td>Jeffrey Vaillancourt</td></tr>
        </table>
        <h2>Remerciements</h2>
        <div className="credits-thanks">
          <p>
            <b>Mine Westwood, IAMGOLD</b><br/>
            Marc Carle, Spécialiste Développement de Formation et Projets Spéciaux<br />
            Marie-Ève Riendeau, Conseillère en communication
          </p>
          <p>
            <b>Comité de scénarisation</b><br/>
            Mario Gonthier, Cégep AT<br />
            Marc Carle, IAMGOLD Mine Westwood<br />
            Nathalie Beaulieu, CSMO mine<br />
            Geneviève Rouleau, INMQ<br />
            Serge Turgeon, Consultant
          </p>
          <p>
            <b>Évaluation du projet</b><br/>
            Sandra Damien, APSM
          </p>
          <p>
            <b>Centre de formation professionnelle Val-d'Or</b>
          </p>
        </div>
        <div class="credits-logo">
          <img src={ INMQLogo } alt="UQAT"/>
          <img src={ IAMGOLDLogo } alt="IAMGOLD"/>
          <img src={ UQATLogo } alt="INMQ"/>
        </div>
      </div>
      <SimpleLink
        label="Retour"
        to="/"
        id="landing-back-link" />
    </div>
  )
}

export default Credits

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class VideoCard extends Component {

  render = () => (
    <div className="video-card">
      <div className="video-background"
           style={{backgroundImage: "url('" + process.env.PUBLIC_URL + "/thumbs/thumb-0" + this.props.chapter.chapter + ".jpg')" }}/>
      <span className="chapter-number video-card-chapter-number">Chapitre { this.props.chapter.chapter }</span>
      <span className="chapter-name video-card-chapter-name">{ this.props.chapter.name }</span>
      <Link to={ "/chapter/" + this.props.id }>
        <div className="play-btn"></div>
      </Link>
    </div>
  )
}


export default VideoCard

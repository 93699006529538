import React, { Component } from 'react'

class AbstractPlayer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      player: {
        duration: 0,
        currentTime: 0,
        buffered: 0,
        ended: false,
        userActivity: true,
      }
    }
  }

  render () {
    let timecode
    let duration
    let positionPercent

    if(isNaN(this.state.player.duration)) {
      timecode = '--:--'
      duration = '--:--'
      positionPercent = 0

    } else {
      timecode = new Date(1000 * this.state.player.currentTime).toISOString().substr(14, 5)
      duration = new Date(1000 * this.state.player.duration).toISOString().substr(14, 5)
      positionPercent = (this.state.player.currentTime / this.state.player.duration) * 100
    }

    return (
      <section
        id="video-player-container"
        className={(this.state.player.userActivity && "show-controls") + " " + (this.props.idle && "idle")}>
        <div className="video-frame-container">
          { this.videoContent() }
        </div>
        <div className={ "video-controls " + (this.props.background && "inactive")}>
          <div className="rewind-btn" onClick={this.goBackward}/>
          <div
            className={"play-btn " + (this.state.player.paused ? "pause" : "play")}
            onClick={this.playPause}/>
          <div className="forward-btn" onClick={this.goForward}/>
        </div>
        <div className="control-strip">
          <div className="progress-position">
            <div className="current-timestamp video-controls-timecode">{ timecode }</div>
            <div className="progress-bar video-progress">
              <div
                className="current-bar"
                style={{width: positionPercent+"%"}}/>
            </div>
            <div className="video-duration video-controls-timecode">{ duration }</div>
          </div>
          <div className="volume-controls">
            <div className="low-volume" />
            <div className="progress-bar volume-level">
              <div className="current-bar" />
            </div>
            <div className="low-volume" />
          </div>
          <div className="subtitles-btn" />
          <div className="video-information">
            <div className="video-controls-chapter-number">Chapitre { this.props.chapter.chapter }</div>
            <div className="video-controls-chapter-name">{ this.props.chapter.name }</div>
          </div>
          <div
            className="chapters-btn"
            onClick={this.props.openChapters}>Chapitres</div>
        </div>
      </section>
    )
  }

  videoContent = () => {
    throw new Error("The method videoContent needs to be implemented by subclasses")
  }

  playPause = () => {
    throw new Error("The method playPause needs to be implemented by subclasses")
  }

  goForward = () => {
    throw new Error("The method goForward needs to be implemented by subclasses")
  }

  goBackward = () => {
    throw new Error("The method goForward needs to be implemented by subclasses")
  }

  goTo = () => {
    throw new Error("The method goTo needs to be implemented by subclasses")
  }

  keyBoardEvent = (e) => {
    console.log(e)
  }
}

AbstractPlayer.defaultProps = {

}

export default AbstractPlayer
